<template>
  <div class="trasaction-result">
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-9 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <h3 class="mb-0">Transaction {{ $route.query.result }}</h3>
            </div>
            <div class="card-body min-vh" v-loading="loading">
              <div class="row justify-content-md-center">
                <div class="col-lg-8 col-md-12">
                  <div>
                    <el-result
                      v-if="$route.query.result === 'success'"
                      icon="success"
                      title="Transaction complete"
                    />
                    <el-result
                      v-else
                      icon="error"
                      :title="`Transaction ${$route.query.result}`"
                    />
                  </div>
                  <div v-if="resultData">
                    <div
                      class="
                        row
                        g-0
                        border
                        rounded
                        overflow-hidden
                        flex-md-row
                        m-2
                        shadow-sm
                        h-md-250
                        position-relative
                      "
                    >
                      <div class="col p-4 d-flex flex-column position-static">
                        <h4 class="mb-0 text-black-50">Paid by</h4>
                        <h3 class="mb-0">{{ bankInfo.name }}</h3>
                      </div>
                      <div class="col-auto d-none d-lg-block">
                        <img :src="bankInfo.logo" width="100px" />
                      </div>
                    </div>
                    <div
                      class="
                        row
                        g-0
                        border
                        rounded
                        overflow-hidden
                        flex-md-row
                        m-2
                        shadow-sm
                        position-relative
                      "
                    >
                      <div class="col-12 p-4">
                        <el-descriptions
                          class="margin-top"
                          title="Transaction detail"
                          :column="1"
                          border
                        >
                          <el-descriptions-item
                            label="Total paid"
                            content-class-name="amount-content"
                            >{{ resultData.paymentAmount.currency }}
                            {{
                              resultData.paymentAmount.value
                            }}</el-descriptions-item
                          >
                          <el-descriptions-item label="Transaction reference">{{
                            resultData.id
                          }}</el-descriptions-item>
                          <el-descriptions-item label="Payment reference">{{
                            resultData.paymentReference
                          }}</el-descriptions-item>
                          <el-descriptions-item label="Payment status" content-class-name="position-relative">
                            <el-tag
                              size="small"
                              :type="
                                resultData.status === 'EXECUTED'
                                  ? 'success'
                                  : 'info'
                              "
                              >{{ resultData.status === 'EXECUTED' ? 'Complete' :  resultData.status}}</el-tag
                            >
                            <el-button 
                              @click="$swal('Information', 
                              `To recall payment, please contact your bank` ,
                              'info')"
                              class="btn-icon-info" 
                              type="text" 
                              icon="el-icon-info"></el-button>
                          </el-descriptions-item>
                          <el-descriptions-item label="Date">
                            {{ getCurrentDate() }}
                          </el-descriptions-item>
                        </el-descriptions>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <Shortcut />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils";
import BankApi from "@/api/bankaccount";
import BankList from "./components/banklist";
import Shortcut from "@/components/MenuShortcut.vue";
export default {
  components:{
    Shortcut
  },
  data() {
    return {
      loading: false,
      resultData: null,
      bankInfo: {},
      bankList: BankList.items,
    };
  },
  methods: {
    getPaymentResult() {
      this.loading = true;
      BankApi.getPisPayment(this.$route.query.paymentInitiationId).then(
        ({ result, data, message }) => {
          this.loading = false;
          if (result) {
            console.log(data);
            this.resultData = data;
            this.bankInfo = this.getBankInfo(data.aspspId);
          } else {
            this.$swal("Error", message, "error");
          }
        }
      );
    },
    getBankInfo(aspspId) {
      const bankPayment = Utils.findObject(this.bankList, aspspId, "id");
      return bankPayment;
    },
    getCurrentDate(){
      return Utils.getCurrentDate('DD/MM/YYYY HH:mm:ss');
    }
  },
  mounted() {
    this.getPaymentResult();
  },
};
</script>

<style lang="scss">
.trasaction-result {
  .el-descriptions-item__label.is-bordered-label {
    width: 180px;
  }

  .amount-content {
    background-color: #d8f5e9;
    font-size: 1rem;
    font-weight: bold !important;
  }
}
</style>