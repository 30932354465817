export default {
  items: [{
      id: "H2100000A1",
      name: "Allied Irish Bank (GB)",
      logo: "/bank-logo/AIB.png",
    },
    {
      id: "H2100000B1",
      name: "Bank of Ireland 365 online",
      logo: "/bank-logo/bank-of-ireland.png",
    },
    {
      id: "H210000032",
      name: "Bank of Scotland Business",
      logo: "/bank-logo/bank-of-scotland.png",
    },
    {
      id: "H210000031",
      name: "Bank of Scotland Personal",
      logo: "/bank-logo/bank-of-scotland.png",
    },
    {
      id: "H210000002",
      name: "Barclays Business",
      logo: "/bank-logo/barclays.png",
    },
    {
      id: "H210000001",
      name: "Barclays Personal",
      logo: "/bank-logo/barclays.png",
    },
    {
      id: "H210000092",
      name: "Danske Business",
      logo: "/bank-logo/danske.png",
    },
    {
      id: "H210000091",
      name: "Danske Personal",
      logo: "/bank-logo/danske.png",
    },
    {
      id: "H210000036",
      name: "first direct",
      logo: "/bank-logo/first-direct.png",
    },
    {
      id: "H210000033",
      name: "Halifax",
      logo: "/bank-logo/halifax.png",
    },
    {
      id: "H210000023",
      name: "HSBC Business",
      logo: "/bank-logo/HSBC.png",
    },
    {
      id: "H210000021",
      name: "HSBC Personal",
      logo: "/bank-logo/HSBC.png",
    },
    {
      id: "H120000001",
      name: "Lloyds",
      logo: "/bank-logo/Lloyds.png",
    },
    {
      id: "H210000062",
      name: "Lloyds Business",
      logo: "/bank-logo/Lloyds.png",
    },
    {
      id: "H210000061",
      name: "Lloyds Personal",
      logo: "/bank-logo/Lloyds.png",
    },
    {
      id: "H210000051",
      name: "Monzo",
      logo: "/bank-logo/monzo.png",
    },
    {
      id: "H210000034",
      name: "Nationwide",
      logo: "/bank-logo/nationwide.png",
    },
    {
      id: "H210000011",
      name: "NatWest",
      logo: "/bank-logo/NatWest.png",
    },
    {
      id: "H210000041",
      name: "Revolut",
      logo: "/bank-logo/revolut.png",
    },
    {
      id: "H120000002",
      name: "Royal Bank of Scotland",
      logo: "/bank-logo/royal-bank-of-scotland.png",
    },
    {
      id: "H210000024",
      name: "Royal Bank of Scotland",
      logo: "/bank-logo/royal-bank-of-scotland.png",
    },
    {
      id: "H210000035",
      name: "Santander",
      logo: "/bank-logo/santander.png",
    },
    {
      id: "H210000081",
      name: "TSB",
      logo: "/bank-logo/TSB.png",
    }
  ],
  test: [
    {
        "id": "H2100000A1",
        "name": "Allied Irish Bank (GB)",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H2100000B1",
        "name": "Bank of Ireland 365 online",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000032",
        "name": "Bank of Scotland Business",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000031",
        "name": "Bank of Scotland Personal",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000002",
        "name": "Barclays Business",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000001",
        "name": "Barclays Personal",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000092",
        "name": "Danske Business",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000091",
        "name": "Danske Personal",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000036",
        "name": "first direct",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000033",
        "name": "Halifax",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000023",
        "name": "HSBC Business",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000021",
        "name": "HSBC Personal",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000062",
        "name": "Lloyds Business",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000061",
        "name": "Lloyds Personal",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000051",
        "name": "Monzo",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000034",
        "name": "Nationwide",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000011",
        "name": "NatWest",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000041",
        "name": "Revolut",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000024",
        "name": "Royal Bank of Scotland",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000035",
        "name": "Santander",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    },
    {
        "id": "H210000081",
        "name": "TSB",
        "capabilities": [
            {
                "type": "SINGLE_IMMEDIATE",
                "status": "ENABLED"
            },
            {
                "type": "STANDING_ORDER",
                "status": "ENABLED"
            }
        ]
    }
]
}